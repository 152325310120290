// import AutoSignIn from "@Components/auth/autosignin";
import { Page, Frame } from "@shopify/polaris";
import SigninTemplates from "@Templates/signinTemplates";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export default function SignInPage() {
  return (
    // <AutoSignIn>
    <Page>
      <Frame>
        <div className="w-full md:w-[348px] m-auto">
          <SigninTemplates />
        </div>
      </Frame>
    </Page>
    // </AutoSignIn>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || "en", [
        "common",
        "navigation",
        "auth",
        "login",
      ])),
      // Will be passed to the page component as props
    },
  };
}
